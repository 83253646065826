import { DocumentModel } from 'api/services/documents/models';
const taxValue = 0.19;
export function documentValueInClp(document: DocumentModel, ufValue: number) {
  const { currency, price, tax } = document;
  const basePrice = currency === 'UF' ? price * ufValue : price;
  // Si el impuesto es "+ IVA", aplica el IVA al precio base
  return tax === '+ IVA' ? basePrice * (1 + taxValue) : basePrice;
}

export function getDocumentsReferenceValue(documents: DocumentModel[], ufValue: number) {
  return documents.reduce((min, document) => {
    const value = documentValueInClp(document, ufValue);
    return value < min ? value : min;
  }, documentValueInClp(documents[0], ufValue));
}
