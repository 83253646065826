import { useState, useEffect, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Soft UI Dashboard PRO React components
import { SuiTypographyTs } from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import LoadingView from "components/Loading";
import { SuiSelectTs } from "components/SuiSelect";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import CreateCommunityModal from "components/Community/CreateCommunity";
import { getPermission } from "utils";
import { useAuthenticationStore } from "stores/authentication";

const AdminCommunities = () => {
  const {
    data: communities,
    dataAsOptions: communitiesOptions,
    isLoading,
  } = useGetCommunities();
  const storedValue = useMemo(() => localStorage.getItem("buscador"), []);
  const [search, setsearch] = useState(JSON.parse(storedValue) || []);
  const userProfile = useAuthenticationStore((state) => state.userProfile);

  useEffect(() => {
    localStorage.setItem("buscador", JSON.stringify(search));
  }, [search]);

  if (isLoading) return <LoadingView />;

  return (
    <Box mt={{ xs: 1, lg: 3 }} mb={1}>
      <Grid container>
        <Grid item xs={12} sm={9}>
          <Box mb={1}>
            <SuiTypographyTs variant="h5">Comunidades</SuiTypographyTs>
          </Box>
          <Box mb={2}>
            <SuiTypographyTs variant="body2" color="text">
              En esta vista puedes ver todas las comunidades en las que
              participas.
            </SuiTypographyTs>
          </Box>
          <SuiTypographyTs variant="body2" color="text">
            Cantidad de comunidades de la administración: {communities.length}
          </SuiTypographyTs>
        </Grid>
        <Grid item xs={12} sm={3} sx={{ pr: 2 }}>
          <SuiSelectTs
            placeholder="Buscar comunidad"
            options={communitiesOptions}
            isMulti
            onChange={(e) => setsearch(e)}
            value={search}
          />
        </Grid>
      </Grid>
      {getPermission(["superadmin"]) && userProfile.management.can_create_communities && <Box pb={1} sx={{display: "flex", justifyContent: 'flex-end'}}><CreateCommunityModal /></Box>}
      <Grid container spacing={3}>
        {communities.length === 0 ? (
          <Grid item xs={12}>
            <Box 
              display="flex" 
              justifyContent="center" 
              alignItems="center" 
              textAlign="center" 
              mt={3} 
              height="60vh"
            >
              <SuiTypographyTs variant="h6" color="error">
                No tienes comunidades asignadas.
              </SuiTypographyTs>
            </Box>
          </Grid>
        ) : (
          communities.map((community, i) => {
            if (search.length === 0 || search.some((obj) => obj.label === community.name)) {
              return (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                  <SimpleBlogCard
                    image={community.picture}
                    title={community.name}
                    community={community}
                    description=""
                    action={{
                      type: "internal",
                      route: "/communities/" + community.slug,
                      color: "info",
                      label: "Ver",
                    }}
                  />
                </Grid>
              );
            }
            return null;
          })
        )}
      </Grid>
    </Box>
  );
};

export default AdminCommunities;
