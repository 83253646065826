import React from "react";
import { useParams } from "react-router-dom";
import TicketView from "components/Tickets/TicketView";
import { UUID } from "crypto";
import { Card, CardContent, Box } from "@mui/material";

const TicketByUUID = () => {
  // Lee el uuid desde los parámetros de la ruta
  const { uuid } = useParams();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1, // Toma el espacio restante en su contenedor padre
        padding: 2, // Agrega un poco de espacio alrededor
        overflow: "auto", // Asegura que el contenido sea desplazable si es necesario
      }}
    >
      <Card
        sx={{
          boxShadow: 3,
          maxWidth: "100vw", // Limita el ancho máximo
          maxHeight: "calc(100vh - 120px)", // Ajusta dinámicamente según la altura disponible (ejemplo: header de 64px)
          width: "100%",
          overflow: "auto", // Permite scroll si el contenido excede el área disponible
        }}
      >
        <CardContent>
          {/* Muestra el componente TicketView con el uuid obtenido */}
          <TicketView ticketUUID={uuid as UUID} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default TicketByUUID;
