
import { ColumnTable } from "components/SwappiTable/types";
import { useMemo } from "react";
import { usePermissions } from "stores/authentication/helpers";
import TableActions from "./TableActions";
import { UserModel } from "api/services/user/models";

export const createColumnsHelper = () => {
  const canEditMaintenance = usePermissions().maintenances.edit;
  return useMemo(() => {
    const columns: ColumnTable<UserModel>[] = [
      { header: "Nombre", accessorKey: "first_name", width: "25%" },
      { header: "Email", accessorKey: "email", width: "25%" },
    ];

    if (canEditMaintenance)
      columns.push({
        header: "Acciones",
        accessorKey: "actions",
        cell: ({ row }) => (
          <TableActions user={row.original} />
        ),
      });

    return columns;
  }, []);
};
