import { useMutation } from "@tanstack/react-query";
import { queryClient } from "api/config/queryClient";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { GET_DISABLED_USERS_QUERY_KEY } from "api/services/user/queries/getDisabledUsers";

const useDeactivateUser = (userUUID: UUID) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/users/${userUUID}/deactivate/`;
      return await axios.patch(url).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Usuario eliminado",
        message: "Usuario eliminado con exito",
      });
    },
  });
};

export const useActivateUser = (userUUID: UUID) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/users/${userUUID}/activate/`;
      return await axios.patch(url).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Usuario activado",
        message: "Usuario activado con exito",
      });
      queryClient.invalidateQueries({queryKey: [GET_DISABLED_USERS_QUERY_KEY]});
    },
  });
}

export default useDeactivateUser;
