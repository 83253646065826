import axios from "axios";
import { API_URL } from "redux/actions/types";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "api/config/queryClient";
import QUERY_KEYS from "api/utils/keys";

export interface CreateCommunityPayload {
  name: string;
  rut?: string;
  [key: string]: any;

}

export const useCreateCommunity = () =>
  useMutation({
    mutationKey: ['CreateCommunity'],
    mutationFn: async (payload: CreateCommunityPayload) => {
      const url = `${API_URL}/users/community/create/`;
      return axios
        .post(url, payload)
        .then((res) => res.data);
    },
    onSuccess: () => {
      // Invalida la cache de las comunidades para refetch
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_COMMUNITIES] });
    },
    onError: (error: any) => {
      console.error("Error creating community:", error);
    },
  });
