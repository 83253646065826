import { API_URL } from "redux/actions/types";
import { DocumentUrlModel } from "../models";
import { useQueries, useQuery } from "@tanstack/react-query";
import semaphore from "api/utils/Semaphore";

export const useGetDocumentUrl = (uuid: string, enabled: boolean) => {
  return useQuery<DocumentUrlModel>({
    enabled: enabled,
    staleTime: 1000 * 60 * 5,
    queryKey: ["GetDocumentUrl", uuid],
    queryFn: async () => {
      await semaphore.acquire(); // Adquirir un "permiso" del semáforo
      const url = `${API_URL}/documents/${uuid}/url/`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = (await response.json()) as DocumentUrlModel;
      semaphore.release(); // Liberar el "permiso" del semáforo
      return data;
    },
  });
};

// Función que utiliza fetch para obtener la URL del documento
const fetchDocumentUrl = async (uuid: string) => {
  const url = `${API_URL}/documents/${uuid}/url/`;
  await semaphore.acquire(); // Adquirir un "permiso" del semáforo
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    data['uuid'] = uuid;
    return data;
  } finally {
    await semaphore.release(); // Asegurarse de liberar el semáforo siempre
  }
};

// Hook para obtener múltiples URLs de documentos
export const useGetDocumentUrls = (uuids: string[], enabled: boolean) => {
  return useQueries({
    queries: uuids.map((uuid) => ({
      queryKey: ["GetDocumentUrl", uuid],
      queryFn: async () => {
        const response = await fetchDocumentUrl(uuid);
        return response;
      }, // Llamada a la función de fetch
      enabled: enabled && !!uuid, // Habilitar solo si está activado y el uuid es válido
      staleTime: 1000 * 60 * 5, // Tiempo que los datos serán considerados frescos
    })),
  });
};
