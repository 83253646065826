import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { SuiButtonTs } from "components/SuiButton";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

import { useCreateCommunity } from "api/services/communities/queries/CreateCommunity";
import { useDispatch } from "react-redux";
import { setNotification } from "redux/actions/communities";
import { styleDesktop, styleDesktopShow, styleMobile } from "components/Tickets/AddTicketModal/styles";
import useMediaScreen from "utils/Hooks/useMedia";
import { SuiInputTs } from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import { Typography } from "@mui/material";
import { GetTicketAssignmentMemebers } from "api/services/tickets/queries";
import { useGetManagementStaff } from "api/services/staff/queries/getManagementStaff";
import { SuiSelectTs } from "components/SuiSelect";

const CreateCommunityModal = () => {
  const { isMobile } = useMediaScreen();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [rut, setRut] = useState("");
  const [assignees, setAssignees] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const dispatch = useDispatch();
  const mutation = useCreateCommunity();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {data: managementStaff} = useGetManagementStaff();

  const handleAssigneesChange = (event) => {
    setSelectedAssignees(event.target.value);
  };

  const handleSubmit = () => {
    if (!name.trim()) {
      dispatch(
        setNotification({
          status: "error",
          message: "Todos los campos son obligatorios.",
          title: "Error al crear comunidad",
        })
      );
      return;
    }
    const parsedAssignees = selectedAssignees.map((assignee) => assignee.value);

    mutation.mutate(
      { name, assignees: parsedAssignees },
      {
        onSuccess: () => {
          dispatch(
            setNotification({
              status: "success",
              message: "Comunidad creada exitosamente.",
              title: "Éxito",
            })
          );
          setOpen(false);
          setName("");
          setRut("");
        },
        onError: () => {
          dispatch(
            setNotification({
              status: "error",
              message: "Hubo un error al crear la comunidad.",
              title: "Error",
            })
          );
        },
      }
    );
  };

  return (
    <div>
      <SuiButtonTs variant="gradient" color="success" size="small" onClick={handleOpen}>
        Crear Comunidad
      </SuiButtonTs>
      <Modal
        style={{ overflow: "visible" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="create-community-modal"
        aria-describedby="create-community-form"
      >
        <Box sx={isMobile ? styleMobile : styleDesktopShow  }>
          <Box p={4}>
          <Typography variant="h4">Crear Comunidad</Typography>
          <Box mt={2}>
          <Typography variant="label" >Nombre</Typography>
            <SuiInputTs
              id="community-name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nombre de la comunidad"
              size="small"
            />
          </Box>
          <Box mt={2}>
            <FormControl fullWidth>
              <SuiSelectTs
                size="small"
                isMulti
                onChange={(e) => setAssignees(e)}
                placeholder="asignados"
                options={managementStaff.map((staff) => ({
                  value: staff.email,
                  label: staff.first_name,
                }))}
                value={assignees}
              />
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <SuiButtonTs
              variant="gradient"
              size="small"
              color="success"
              onClick={handleSubmit}
              disabled={mutation.isPending}
            >
              {mutation.isPending ? "Creando..." : "Crear"}
            </SuiButtonTs>
          </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateCommunityModal;
