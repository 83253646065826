import { Box, Checkbox, Tooltip } from "@mui/material";
import { SelectedVisitsReportsState } from "../types";
import { VisitReportModel, VisitsReportPage } from "api/services/visits/types";
import MemberList from "components/Tickets/MemberList";
import { parseDate } from "utils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ColumnTable } from "components/SwappiTable/types";
import VisitsReportsActions from "components/VisitsReports/VisistsReportsActions";

interface ColumnsRequired {
  data: VisitsReportPage;
  selectedVisitsReports: SelectedVisitsReportsState;
  addVisits: (item: VisitReportModel[]) => void;
  removeVisits: (item: VisitReportModel[]) => void;
}
export const getColumns = ({
  data,
  selectedVisitsReports,
  addVisits,
  removeVisits,
}: ColumnsRequired): ColumnTable<VisitReportModel>[] => {
  const handleCheckbox = (checked: boolean, item: VisitReportModel) => {
    if (checked) addVisits([item]);
    else removeVisits([item]);
  };
  const handleCheckAll = (checkAll: boolean) => {
    if (checkAll) addVisits(data.results);
    else removeVisits(data.results);
  };

  const validateCheckAll = () =>
    data?.results.every((report) => {
      return selectedVisitsReports[report.uuid] !== undefined;
    });

  const allChecked = validateCheckAll();
  return [
    {
      header: () =>
        (
          <Box onClick={() => handleCheckAll(!allChecked)} display={"flex"}>
            <Checkbox checked={allChecked} />
            <Tooltip
              title="Esta opción selecciona solo la hoja actual"
              placement="top"
            >
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Box>
        ),
      accessorKey: "uuid",
      cell: ({ row }) => (
        <Checkbox
          checked={selectedVisitsReports[row.original.uuid] !== undefined}
          onChange={(value) =>
            handleCheckbox(value.target.checked, row.original)
          }
        />
      ),
    },
    {
      header: "Titulo",
      accessorKey: "title",
      cell: ({ row }) => row.original.title,
    },
    {
      header: "Comunidad",
      accessorKey: "community",
      cell: ({ row }) => row.original.community.name,
    },
    {
      header: "Usuario",
      accessorKey: "usuario",
      cell: ({ row }) => <MemberList members={[row.original.author]} />,
    },
    {
      header: "Creado",
      accessorKey: "created",
      cell: ({ row }) => parseDate(row.original.created),
    },
    {
      header: "Acciones",
      accessorKey: "uuid",
      cell: ({ row }) => {
        return (
          <Box>
            <VisitsReportsActions report={row.original} />
          </Box>
        );
      },
    },
  ];
};
