import { getPermission } from 'utils';
import { types, API_URL } from './types';
import Axios from 'axios';
import { queryClient } from 'api/config/queryClient';
import QUERY_KEYS from 'api/utils/keys';

export const getCommunitiesByUser = (d) => {
  const url = `${API_URL}/users/communities/`;
  return (dispatch) => {
    let outputData = {};
    if (getPermission(['superadmin', 'administrador', 'supervisor'])) {
      Axios.get(url, { params: d }).then(({ data }) => {
        outputData['data'] = data;
        dispatch(setGetCommunitiesByUser(outputData));
      });
    }
  };
};

export const setGetCommunitiesByUser = (data) => ({
  type: types.setGetCommunitiesByUser,
  payload: data,
});

export const getCommunity = (d) => {
  const url = `${API_URL}/users/community/` + d + `/`;
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      outputData['data'] = data;
      dispatch(setGetCommunityBySlug(outputData));
    });
  };
};

export const createBuilding = (slug, d) => {
  const url = `${API_URL}/users/community/${slug}/building/create`;
  return (dispatch) => {
    let outputData = {};
    Axios.post(url, d).then(({ data }) => {
      outputData['data'] = data;
      outputData['status'] = 200;
      let notification = {
        status: 'success',
        message: 'El edificio ha sido creado con éxito!',
        title: 'Edificio creado',
      };
      dispatch(setCreateBuilding(outputData));
      dispatch(setNotification(notification));
      dispatch(getBuildingsByCommunity(slug));
    });
  };
};

export const getUnits = (slug) => {
  const url = `${API_URL}/users/community/${slug}/units`;
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setGetUnits(outputData));
    });
  };
};

/* Get Areas */
export const getAreas = (slug) => {
  const url = `${API_URL}/users/community/${slug}/areas`;

  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      console.log({ url, data });
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setGetAreas(outputData));
    });
  };
};

/* Set get areas */
export const setGetAreas = (data) => ({
  type: types.setGetAreas,
  payload: data,
});

/* Create Area */
export const createArea = (slug, d) => {
  const url = `${API_URL}/users/community/${slug}/areas`;
  return (dispatch) => {
    let outputData = {};
    Axios.post(url, d).then(({ data }) => {
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setCreateArea(outputData));
      let notification = {
        status: 'success',
        message: 'El área ha sido creada con éxito!',
        title: 'Área creada',
      };
      dispatch(setNotification(notification));
    });
  };
};
/* Set Create Area */
export const setCreateArea = (data) => ({
  type: types.setCreateArea,
  payload: data,
});

/* Create Unit */
export const createUnit = (slug, d) => {
  const url = `${API_URL}/users/community/${slug}/units`;
  return (dispatch) => {
    let outputData = {};
    Axios.post(url, d)
      .then((data) => {
        if (data.error) {
          return;
        }
        outputData['data'] = data;
        outputData['status'] = 200;
        dispatch(setCreateUnit(outputData));
      })
      .catch((error) => {
        console.log(error, 'ERROR');
      });
  };
};

/* Set Create Unit */
export const setCreateUnit = (data) => ({
  type: types.setCreateUnit,
  payload: data,
});

/* Get visits by community */
export const getVisitsByCommunity = (slug) => {
  const url = `${API_URL}/visit/by-community/${slug}/`;
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setGetVisitsByCommunity(outputData));
    });
  };
};

export const setGetVisitsByCommunity = (data) => ({
  type: types.setGetVisitsByCommunity,
  payload: data,
});

/* Get visits by management */
export const getVisitsByManagement = (slug) => {
  const url = `${API_URL}/visit/by-management/${slug}/`;
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setGetVisitsByManagement(outputData));
    });
  };
};

export const markAsVisited = (slug, d) => {
  const url = `${API_URL}/visit/mark-as-completed/${slug}/`;
  return (dispatch) => {
    let outputData = {};
    Axios.put(url, d).then(({ data }) => {
      if (data.error) {
        return;
      }
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setMarkAsVisited(outputData));
      let notification = {
        status: 'success',
        message: 'La visita ha sido completada con éxito!',
        title: 'Visita completada',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const getBuildingsByCommunity = (slug) => {
  const url = `${API_URL}/users/community/${slug}/buildings/`;
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setGetBuildingsByCommunity(outputData));
    });
  };
};

export const editVisit = (slug, d) => {
  const url = `${API_URL}/visit/${slug}/edit`;
  return (dispatch) => {
    let outputData = {};
    Axios.put(url, d).then(({ data }) => {
      if (data.error) {
        return;
      }
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setEditVisit(outputData));
      let notification = {
        status: 'success',
        message: 'La visita ha sido editada con éxito!',
        title: 'Visita editada',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const deleteVisit = (slug) => {
  const url = `${API_URL}/visit/${slug}/delete`;
  return (dispatch) => {
    let outputData = {};
    Axios.delete(url).then(({ data }) => {
      if (data.error) {
        return;
      }
      outputData['data'] = data;

      outputData['status'] = 200;
      dispatch(setDeleteVisit(outputData));
      let notification = {
        status: 'success',
        message: 'La visita ha sido eliminada con éxito!',
        title: 'Visita eliminada',
      };
      dispatch(setNotification(notification));
    });
  };
};
export const setDeleteVisit = (data) => ({
  type: types.setDeleteVisit,
  payload: data,
});

export const editArea = (slug, d) => {
  const url = `${API_URL}/users/community/${slug}/areas`;
  return (dispatch) => {
    let outputData = {};
    Axios.put(url, d)
      .then(({ data }) => {
        if (data.error) {
          return;
        }
        outputData['data'] = data;
        outputData['status'] = 200;
        dispatch(setEditArea(outputData));
        let notification = {
          status: 'warning',
          message: 'El área ha sido editada con éxito!',
          title: 'Área editada',
        };
        dispatch(setNotification(notification));
      })
      .catch((error) => {
        console.log(error, 'ERROR');
      });
  };
};

export const deleteArea = (slug, d) => {
  const url = `${API_URL}/users/community/area/${slug}/delete`;
  return (dispatch) => {
    let outputData = {};
    Axios.delete(url, d)
      .then(({ data }) => {
        if (data.error) {
          return;
        }
        outputData['data'] = data;
        outputData['status'] = 200;
        dispatch(setDeleteArea(outputData));
        let notification = {
          status: 'error',
          message: 'El área ha sido eliminada con éxito!',
          title: 'Área eliminada',
        };
        dispatch(setNotification(notification));
      })
      .catch((error) => {
        console.log(error, 'ERROR');
      });
  };
};

export const updateCommunityImage = (slug, d) => {
  const url = `${API_URL}/users/community/${slug}/update/`;
  return (dispatch) => {
    let outputData = {};
    Axios.put(url, d)
      .then(({ data }) => {
        if (data.error) {
          return;
        }
        outputData['data'] = data;
        outputData['status'] = 200;
        dispatch(setUpdateCommunityImage(outputData));
        let notification = {
          status: 'success',
          message: 'La imagen de la comunidad ha sido editada con éxito!',
          title: 'Imagen editada',
        };
        dispatch(setNotification(notification));
        queryClient.invalidateQueries([QUERY_KEYS.GET_COMMUNITY_IMAGE, slug]);
      })
      .catch((error) => {
        console.log(error, 'ERROR');
      });
  };
};

export const setUpdateCommunityImage = (data) => ({
  type: types.setUpdateCommunityImage,
  payload: data,
});

export const setEditArea = (data) => ({
  type: types.setEditArea,
  payload: data,
});

export const setEditVisit = (data) => ({
  type: types.setEditVisit,
  payload: data,
});

export const setGetBuildingsByCommunity = (data) => ({
  type: types.setGetBuildingsByCommunity,
  payload: data,
});

export const setMarkAsVisited = (data) => ({
  type: types.setMarkAsVisited,
  payload: data,
});

export const setGetVisitsByManagement = (data) => ({
  type: types.setGetVisitsByManagement,
  payload: data,
});

export const setGetUnits = (data) => ({
  type: types.setGetUnits,
  payload: data,
});

export const setCreateBuilding = (data) => ({
  type: types.setCreateBuilding,
  payload: data,
});

export const setGetCommunityBySlug = (data) => ({
  type: types.setGetCommunity,
  payload: data,
});

export const setNotification = (data) => ({
  type: types.setNotification,
  payload: data,
});

export const setDeleteArea = (data) => ({
  type: types.setDeleteArea,
  payload: data,
});

export const getCommunityStaff = (slug) => {
  const url = `${API_URL}/users/community/${slug}/staff/`;
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setGetCommunityStaff(outputData));
    });
  };
};

export const setGetCommunityStaff = (data) => ({
  type: types.setGetCommunityStaff,
  payload: data,
});

export const createVisitReport = (slug) => {
  const url = `${API_URL}/analytics/report/`;

  return (dispatch) => {
    let outputData = {};
    let d = { community: slug };
    Axios.post(url, d).then(({ data }) => {
      console.log(data, 'DATA');
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setCreateVisitReport(outputData));
      let notification = {
        status: 'success',
        message: 'El reporte de la visita ha sido creado con éxito!',
        title: 'Reporte creado',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const setCreateVisitReport = (data) => ({
  type: types.setCreateVisitReport,
  payload: data,
});

export const updateVisitReport = (uuid, d) => {
  const url = `${API_URL}/analytics/report`;
  return (dispatch) => {
    let outputData = {};
    Axios.put(url, d).then(({ data }) => {
      if (data.error) {
        return;
      }
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setUpdateVisitReport(outputData));
      let notification = {
        status: 'success',
        message: 'El reporte de la visita ha sido editado con éxito!',
        title: 'Reporte editado',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const setUpdateVisitReport = (data) => ({
  type: types.setUpdateVisitReport,
  payload: data,
});

export const getVisitReport = (uuid) => {
  const url = `${API_URL}/analytics/report/${uuid}/`;
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      if (data.error) {
        return;
      }
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setGetVisitReport(outputData));
    });
  };
};

export const setGetVisitReport = (data) => ({
  type: types.setGetVisitReport,
  payload: data,
});

export const getPublicVisitReport = (uuid) => {
  const url = `${API_URL}/analytics/visit/report/${uuid}/public/`;
  return (dispatch) => {
    let outputData = {};
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          return;
        }
        outputData['data'] = data;
        outputData['status'] = 200;
        console.log(outputData, 'OUTPUT DATA');
        dispatch(setGetVisitReport(outputData));
      });
  };
};

export const getCommunityGantt = (slug) => {
  const url = `${API_URL}/visits/community-maintenances/${slug}/`;
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      if (data.error) {
        return;
      }
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setGetCommunityGantt(outputData));
    });
  };
};

export const setGetCommunityGantt = (data) => ({
  type: types.setGetCommunityGantt,
  payload: data,
});

export const editBuilding = (uuid, d) => {
  //http://127.0.0.1:8000/users/community/building/68c2dcfe-8cd7-4a7b-a126-d2aa1f524273/edit/

  const url = `${API_URL}/users/community/building/${uuid}/edit/`;
  return (dispatch) => {
    let outputData = {};
    Axios.put(url, d).then(({ data }) => {
      if (data.error) {
        return;
      }
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setEditBuilding(outputData));
      let notification = {
        status: 'success',
        message: 'El edificio ha sido editado con éxito!',
        title: 'Edificio editado',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const setEditBuilding = (data) => ({
  type: types.setEditBuilding,
  payload: data,
});

export const deleteBuilding = (uuid) => {
  //http://127.0.0.1:8000/users/community/building/68c2dcfe-8cd7-4a7b-a126-d2aa1f524273/delete/
  const url = `${API_URL}/users/community/building/${uuid}/delete/`;
  return (dispatch) => {
    let outputData = {};
    Axios.delete(url).then((data) => {
      if (data.error) {
        return;
      }
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setDeleteBuilding(outputData));
      let notification = {
        status: 'success',
        message: 'El edificio ha sido eliminado con éxito!',
        title: 'Edificio eliminado',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const setDeleteBuilding = (data) => ({
  type: types.setDeleteBuilding,
  payload: data,
});

export const getRegionsByCountry = (country) => {
  const url = `${API_URL}/regions/${country}/`;
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      if (data.error) {
        return;
      }
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setGetRegionsByCountry(outputData));
    });
  };
};

export const setGetRegionsByCountry = (data) => ({
  type: types.setGetRegionsByCountry,
  payload: data,
});

export const getCommunesByRegions = (region) => {
  const url = `${API_URL}/communes/${region}/`;
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      if (data.error) {
        return;
      }
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setGetCommunesByRegions(outputData));
    });
  };
};

export const setGetCommunesByRegions = (data) => ({
  type: types.setGetCommunesByRegions,
  payload: data,
});

export const getActionPlan = (d) => {
  const url = `${API_URL}/action-plan/`;
  return (dispatch) => {
    let outputData = {};
    Axios.post(url, d).then(({ data }) => {
      if (data.error) {
        return;
      }
      outputData['data'] = data;
      outputData['status'] = 200;
      dispatch(setGetActionPlan(outputData));
    });
  };
};

export const setGetActionPlan = (data) => ({
  type: types.setGetActionPlan,
  payload: data,
});

export const importAreas = (d) => {
  const url = `${API_URL}/users/community/area/upload/`;
  return (dispatch) => {
    let outputData = {};
    Axios.post(url, d).then(({ data }) => {
      if (data.error) {
        return;
      }
      outputData['data'] = data;
      outputData['status'] = 200;
      let notification = {
        status: 'success',
        message: 'Las areas han sido importadas!',
        title: 'Areas importadas',
      };
      dispatch(setImportAreas(outputData));
      dispatch(setNotification(notification));
    });
  };
};

export const setImportAreas = (data) => ({
  type: types.setImportAreas,
  payload: data,
});
