import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiDatePicker from "components/SuiDatePicker";
// Soft UI Dashboard PRO React components
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import PieChart from "examples/Charts/PieChart";
import LoadingView from "components/Loading";
import { getDashboard, getSupervisorDashboard } from "redux/actions/analytics";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import { getPermission } from "utils";
import {getLineChartDataByMonth, getWorkloadFormattedData, getPieFormattedData } from "layouts/pages/charts/utils";

function Charts() {
  const dispatch = useDispatch();
  const dashboardResponse = useSelector(
    (state) => state.analytics.getDashboard
  );
  const supervisorDashboardResponse = useSelector(
    (state) => state.analytics.getSupervisorDashboard
  );
  const communitiesResponse = useGetCommunities();

  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState({});

  useEffect(() => {
    setLoading(true);
    if (getPermission("administrador") || getPermission("superadmin")) {
      dispatch(
        getDashboard({
          communities: selectedCommunity,
          created_before: endDate,
          created_after: startDate,
        })
      );
    } else if (getPermission("supervisor")) {
      dispatch(
        getSupervisorDashboard({
          communities: selectedCommunity,
          created_before: endDate,
          created_after: startDate,
        })
      );
    }
  }, [startDate, endDate, selectedCommunity]);

  useEffect(() => {
    if (dashboardResponse.data) {
      setDashboard(dashboardResponse.data);
      setLoading(false);
    }
  }, [dashboardResponse]);

  useEffect(() => {
    if (supervisorDashboardResponse.data) {
      setDashboard(supervisorDashboardResponse.data);
      setLoading(false);
    }
  }, [supervisorDashboardResponse]);

  if (loading) return <LoadingView />;

  // Verificar si el dashboard está vacío
  const isDashboardEmpty = Object.keys(dashboard).length === 0;

  return (
    <SuiBox my={3}>
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} md={4}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Comunidad
          </SuiTypography>
          <SuiSelect
            size="small"
            value={selectedCommunity}
            onChange={(e) => setSelectedCommunity(e.value)}
            options={
              communitiesResponse.data
                ? communitiesResponse.data.map((e) => ({
                    value: e.slug,
                    label: e.name,
                  }))
                : []
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Fecha inicio
          </SuiTypography>
          <SuiDatePicker onChange={(e) => setStartDate(e[0].toISOString())} />
        </Grid>
        <Grid item xs={12} md={4}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Fecha Término
          </SuiTypography>
          <SuiDatePicker onChange={(e) => setEndDate(e[0].toISOString())} />
        </Grid>
      </Grid>

      {isDashboardEmpty ? (
        <SuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          mt={3}
          height="60vh"
        >
          <SuiTypography variant="h6" color="error">
            No tienes información para mostrar, comienza a usar Swappi para
            poder ver métricas relevantes.
          </SuiTypography>
        </SuiBox>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <MiniStatisticsCard
                title={{
                  text: "Duración promedio de tareas",
                  fontWeight: "bold",
                }}
                count={dashboard.avg_duration?.toFixed(1) + " días"}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MiniStatisticsCard
                title={{ text: "Tareas finalizadas", fontWeight: "bold" }}
                count={dashboard.closed_tickets}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MiniStatisticsCard
                title={{ text: "Tareas abiertas", fontWeight: "bold" }}
                count={dashboard.open_tickets}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MiniStatisticsCard
                title={{ text: "Total de tareas", fontWeight: "bold" }}
                count={dashboard.ticket_count}
              />
            </Grid>
          </Grid>

          <SuiBox mb={3} mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                {dashboard.tickets_closed_by_month && (
                  <DefaultLineChart
                    title="Cantidad de tareas cerradas por mes"
                    chart={getLineChartDataByMonth(
                      dashboard.tickets_closed_by_month
                    )}
                  />
                )}
              </Grid>
              {getPermission(["superadmin", "administrador"]) && (
                <Grid item xs={12} md={6}>
                  {dashboard.staff_workloads && (
                    <HorizontalBarChart
                      title="Carga de trabajo por usuario"
                      chart={getWorkloadFormattedData(
                        dashboard.staff_workloads,
                        "Tareas por usuario",
                        "primary"
                      )}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                {dashboard.community_workloads && (
                  <HorizontalBarChart
                    title="Carga de trabajo por comunidad"
                    chart={getWorkloadFormattedData(
                      dashboard.community_workloads,
                      "Tareas por comunidad",
                      "info"
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {dashboard.ticket_state_distribution && (
                  <PieChart
                    title="Distribución de tareas por estado"
                    chart={getPieFormattedData(
                      dashboard.ticket_state_distribution
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {dashboard.ticket_priority_distribution && (
                  <DefaultDoughnutChart
                    title="Distribución de tareas por prioridad"
                    chart={getPieFormattedData(
                      dashboard.ticket_priority_distribution
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </SuiBox>
        </>
      )}
    </SuiBox>
  );
}

export default Charts;
