import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Form from './Form';
import SuiButton from 'components/SuiButton';
import { mobileMaxWidth } from 'utils';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxHeight: '70vh',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
  p: 1,
  paddingTop: 3,
};

const mobileStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 5,
  bgcolor: 'background.paper',
  maxHeight: '90vh',
  p: 0,
  width: '100%',
};

const navButtonStyle = {
  position: 'fixed', // Fijo a la ventana
  top: '50%',
  zIndex: 1300, // Asegúrate de que está por encima del modal
  transform: 'translateY(-50%)', // Centra los botones verticalmente
};

export default function VisitsModal(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [visit, setVisit] = useState(props.visit);
  const handleClose = () => {
    setOpen(false);
    setVisit({});
  };

  const handlePrev = () => {
    props.handlePrev();
  };

  const handleNext = () => {
    props.handleNext();
  };

  useEffect(() => {
    if (props.visit) {
      if (visit) {
        if (props.visit.uuid !== visit.uuid) {
          handleOpen();
          setVisit(props.visit);
        }
      } else {
        handleOpen();
        setVisit(props.visit);
      }
    }
  }, [props.visit]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={window.innerWidth <= mobileMaxWidth ? mobileStyle : style}>
          <Form visit={props.visit} close={handleClose} />
        </Box>
      </Modal>

      {/* Botones de navegación */}
      {open && props.handlePrev && (
        <SuiButton
          variant="gradient"
          color="info"
          size="small"
          onClick={handlePrev}
          sx={{ ...navButtonStyle, left: '20px' }}
          startIcon={<AiOutlineLeft size="20px" />}
        />
      )}
      {open && props.handleNext && (
        <SuiButton
          variant="gradient"
          color="info"
          size="small"
          onClick={handleNext}
          sx={{ ...navButtonStyle, right: '20px' }}
          endIcon={<AiOutlineRight size="20px" />}
        />
      )}
    </div>
  );
}
