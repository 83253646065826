import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import SwappiDataTable from "components/SwappiTable";
import { createColumnsHelper } from "./table.helper";
import { useGetDisabledUsers } from "api/services/user/queries/getDisabledUsers";

const DisabledUsers = () => {
  const columns = createColumnsHelper();
  const { data: users } = useGetDisabledUsers();

  return (
    <Card sx={{ margin: 2, boxShadow: 3 }}>
      <CardHeader
        title={<Typography variant="h6">Usuarios Desactivados</Typography>}
        sx={{ backgroundColor: "#f5f5f5", borderBottom: "1px solid #ddd" }}
      />
      <CardContent>
        {users && users.length > 0 ? (
          <SwappiDataTable columns={columns} data={users} />
        ) : (
          <Typography variant="body1" color="textSecondary">
            No hay usuarios desactivados disponibles.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default DisabledUsers;