import React, { useState, useEffect, useMemo } from "react";
import LoadingView from "components/Loading";
import { Box, Card, Divider, Button } from "@mui/material";
import UserProfileInfoCard from "components/modules/team/UserProfileInfoCard";
import { customSort } from "components/modules/team/TeamUsersList.helper";
import Documents from "../documents";
import SwappiTabs from "components/ui/SwappiTabs";
import TicketsTable from "components/Tickets/TicketsTable";
import CommunitiesViewer from "components/modules/communities/CommunitiesViewer";
import { SlugString } from "types/models";
import TeamUsersList from "components/modules/team/TeamUsersList";
import { useGetCommunityStaff, useGetUsersStaff } from "api/services/user/queries/getUsersStaff";
import DisabledUsers from "./components/Post/Members/DisabledUsers";
import SuiButton, { SuiButtonTs } from "components/SuiButton";

interface Props {
  communitySlug?: SlugString;
}

const TeamManagement: React.FC<Props> = ({ communitySlug }) => {
  const { data: userStaff, isLoading } = useGetUsersStaff({
    communitySlug,
  });

  const { data: userCommunityStaff } = useGetCommunityStaff({
    communitySlug,
  });

  const [userUUIDSelected, setUserUUIDSelected] = useState();
  const [showDisabledUsers, setShowDisabledUsers] = useState(false); // Estado para alternar vistas

  const teamUsers = useMemo(() => customSort(userStaff), [userStaff]);

  const communityStaff = useMemo(() => customSort(userCommunityStaff), [userCommunityStaff]);

  const userSelected = useMemo(() => {
    if (communitySlug) {
      return userCommunityStaff.find((user) => user.uuid === userUUIDSelected);
    }
    return userStaff.find((user) => user.uuid === userUUIDSelected);
  }, [userUUIDSelected, userStaff]);

  const handleSelectUser = (user) => {
    setUserUUIDSelected(user.uuid);
  };

  useEffect(() => {
    if (communitySlug && communityStaff.length > 0) {
      handleSelectUser(communityStaff[0]);
    }
    if (teamUsers.length > 0) {
      handleSelectUser(teamUsers[0]);
    }
  }, [teamUsers.length, communityStaff.length]);

  const tabs = [
    ...(communitySlug
      ? []
      : [
          {
            label: "Comunidades",
            content: (
              <CommunitiesViewer communities={userSelected?.communities} user={userSelected} />
            ),
          },
        ]),
    {
      label: "Documentos",
      content: <Documents user={userSelected} communitySlug={communitySlug} />,
    },
    {
      label: "Tareas",
      content: (
        <TicketsTable
          params={{
            responsible: userSelected?.email,
            communities: communitySlug,
          }}
        />
      ),
    },
  ];

  if (isLoading) return <LoadingView />;

  return (
    <Box>
      {/* Botón para alternar vistas */}
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <SuiButtonTs
          variant="gradient"
          color="primary"
          size="small"
          onClick={() => setShowDisabledUsers((prev) => !prev)}
        >
          {showDisabledUsers ? "Ver Usuarios Activos" : "Ver Usuarios Eliminados"}
        </SuiButtonTs>
      </Box>

      {showDisabledUsers ? (
        // Vista de usuarios desactivados
        <DisabledUsers />
      ) : (
        // Vista de usuarios activos
        <>
          <Box>
            <TeamUsersList
              users={communitySlug ? userCommunityStaff : teamUsers}
              userSelected={userSelected}
              onSelect={handleSelectUser}
              communitySlug={communitySlug}
            />
          </Box>
          {userSelected && (
            <Box mt={3}>
              <Box>
                <UserProfileInfoCard user={userSelected} />
              </Box>
              <Divider />
              <Card sx={{ p: 3 }}>
                <SwappiTabs tabs={tabs} />
              </Card>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default TeamManagement;
