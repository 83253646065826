import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "redux/actions/types";
import { SelectOptionForm } from "types/forms";
import { userToken } from "utils/user/user";
import { UserModel } from "api/services/user/models";
import useEventListener from "lib/events/useEventListener";
import { queryClient } from "api/config/queryClient";
import { SlugString } from "types/models";
import { useMemo } from "react";

export const KEY_GET_USER_STAFF = "GetUserStaff";

interface Params {
  communitySlug?: SlugString;
}

export const useGetUsersStaff = (params: Params) => {
  const query = useQuery({
    queryKey: [KEY_GET_USER_STAFF],
    queryFn: async () => {
      const { slug } = userToken().management;
      const url = `${API_URL}/users/manager/${slug}/staff`;
      return axios.get<UserModel[]>(url).then((res) => res.data);
    },
  });
  const data = useMemo(() => {
    if (params.communitySlug)
      return (
        query.data?.filter((item) =>
          item.communities.some(
            (community) => community.slug === params.communitySlug
          )
        ) || []
      );
    return query.data || [];
  }, [query.data, params.communitySlug]);


  const dataAsOptions: SelectOptionForm[] = data.map((member) => ({
    value: member.email,
    label: member.first_name,
  }));

  useEventListener(["userDeactivated", "userCreated", "userActivated"], () => {
    query.refetch();
  });
  useEventListener<UserModel>("userUpdated", (data) => {
    queryClient.setQueryData([KEY_GET_USER_STAFF], (state: UserModel[]) => {
      console.log(state, data);
      return state?.map((user) => {
        if (user.uuid === data.uuid) return data;
        return user;
      });
    });
  });

  return { ...query, dataAsOptions, data };
};




export const useGetCommunityStaff = (params: Params) => {
  const query = useQuery({
    enabled: !!params.communitySlug,
    queryKey: [KEY_GET_USER_STAFF, params.communitySlug],
    queryFn: async () => {
      if (!params.communitySlug) return []; // Devuelve un array vacío si no hay un slug de comunidad.
      const url = `${API_URL}/users/community/${params.communitySlug}/staff/`;
      return axios.get<UserModel[]>(url).then((res) => res.data);
    },
  });
  const data = useMemo(() => {
    if (params.communitySlug)
      return (
        query.data?.filter((item) =>
          item.communities.some(
            (community) => community.slug === params.communitySlug
          )
        ) || []
      );
    return query.data || [];
  }, [query.data, params.communitySlug]);


  const dataAsOptions: SelectOptionForm[] = data.map((member) => ({
    value: member.email,
    label: member.first_name,
  }));

  useEventListener(["userDeactivated", "userCreated", "userActivated"], () => {
    query.refetch();
  });
  useEventListener<UserModel>("userUpdated", (data) => {
    queryClient.setQueryData([KEY_GET_USER_STAFF, params.communitySlug], (state: UserModel[]) => {
      return state?.map((user) => {
        if (user.uuid === data.uuid) return data;
        return user;
      });
    });
  });

  return { ...query, dataAsOptions, data };
};

