import { Delete, Edit } from "@mui/icons-material";
import { Box } from "@mui/material";
import { UserModel } from "api/services/user/models";
import { useActivateUser } from "api/services/user/mutations/deactivateUser";
import { SuiBadgeTs } from "components/SuiBadge";
import useEventEmitter from "lib/events/useEventEmitter";
import React from "react";
import { FaPerson } from "react-icons/fa6";
import Swal from "sweetalert2";

const TableActions: React.FC<{
  user: UserModel;
}> = ({ user}) => {
  const activateUser = useEventEmitter("userActivated");
  const { mutate: activateUserMutation } = useActivateUser(user.uuid);
  const handleDeleteBudget = () => {
    Swal.fire({
      title: "¿Quieres activar este usuario?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        activateUserMutation(null, {
          onSuccess: (data) => {
            activateUser.emit(data);
          },
        });
      }
    });
  };
  return (
    <Box display={"flex"} gap={1}>
      <SuiBadgeTs
        style={{ cursor: "pointer" }}
        onClick={handleDeleteBudget}
        badgeContent={<FaPerson />}
        color="success"
        variant="gradient"
        container
      />
    </Box>
  );
};

export default TableActions;
