import { API_URL } from "redux/actions/types";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useEventListener from "lib/events/useEventListener";

export const GET_DISABLED_USERS_QUERY_KEY = "GetDisabledUsers";

export const useGetDisabledUsers = () => {

  const query = useQuery({
    queryKey: [GET_DISABLED_USERS_QUERY_KEY],
    queryFn: async () => {
      const url = `${API_URL}/users/deactivated-users/`;
      return axios.get(url).then((res) => res.data);
    },
    staleTime: 0, // Datos considerados siempre frescos por 0ms
  });

  useEventListener(["userDeactivated", "userCreated", "userActivated"], () => {
    console.log("Evento recibido: Refrescando datos...");
    query.refetch();
  });

  return { ...query };
};
