import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { SuiSelectTs } from "components/SuiSelect";
import { SuiTypographyTs } from "components/SuiTypography";
import { SuiBoxTs } from "components/SuiBox";
import DataTableHeadCell from "../../examples/Tables/DataTablePagination/DataTableHeadCell";
import DataTableBodyCell from "../../examples/Tables/DataTablePagination/DataTableBodyCell";
import { SuiPaginationTs } from "components/SuiPagination";
import { SuiInputTs } from "components/SuiInput";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { DataTableProps } from "./types";
import SearchInput from "./SearchInput";
import useDebounce from "utils/Hooks/useDebounce";

const SwappiDataTable: FC<DataTableProps> = ({
  pageSizeOptions = [5, 10, 15, 20, 25, 30, 35, 40, 60, 80, 100],
  onChangePageSize,
  onEmpty,
  defaultPageSize,
  columns = [],
  data = [],
  pagination,
  isLoading,
  collapseRender: collapseContent,
  localSearch,
}) => {
  const [pageSize, setPageSize] = useState(
    defaultPageSize || pageSizeOptions[0]
  );

  // Definir columnas con react-table
  const columnsData = useMemo<ColumnDef<any>[]>(() => columns, [columns]);
  const filteredData = useMemo(() => data, [data]);

  const table = useReactTable({
    data: filteredData,
    columns: columnsData,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  const handleOnInputChange = useDebounce((value: string) => {
    table.setGlobalFilter(value);
  }, 1000);

  return (
    <Box>
      <Box display={"flex"} justifyContent={"space-between"} mb={2}>
        <Box>
          {onChangePageSize && (
            <>
              <SuiTypographyTs
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                Entradas por página
              </SuiTypographyTs>
              <SuiSelectTs
                options={pageSizeOptions.map((entry) => ({
                  value: entry,
                  label: entry,
                }))}
                value={pageSize}
                onChange={(e) => {
                  setPageSize(e.value);
                  onChangePageSize(e.value);
                }}
                size="small"
              />
            </>
          )}
        </Box>
        <Box>
          {localSearch && (
            <SearchInput
              onChange={handleOnInputChange}
              initialValue={table.initialState.globalFilter}
            />
          )}
        </Box>
      </Box>
      <TableContainer
        sx={{ boxShadow: "none", borderRadius: 0 }}
        className={"custom-scroll"}
      >
        <Table>
          <SuiBoxTs
            bgColor={"info"}
            variant={"gradient"}
            color="white"
            component="thead"
          >
            <TableRow>
              {collapseContent && <DataTableHeadCell>#</DataTableHeadCell>}
              {table.getFlatHeaders().map((header, index) => {
                console.log(header);
                return (
                  <DataTableHeadCell key={index} colSpan={header.colSpan} >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </DataTableHeadCell>
                );
              })}
            </TableRow>
          </SuiBoxTs>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={columnsData.length}>
                  <Box sx={{ textAlign: "center" }}>
                    <CircularProgress size={20} color="info" />
                  </Box>
                </TableCell>
              </TableRow>
            ) : filteredData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columnsData.length}>
                  <Box sx={{ textAlign: "center" }}>
                    {onEmpty || "Sin resultados"}
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              table.getRowModel().rows.map((row, index) => (
                <Fragment key={index}>
                  <TableRow>
                    {collapseContent && (
                      <DataTableBodyCell px={0}>
                        <IconButton onClick={row.getToggleExpandedHandler()}>
                          {row.getIsExpanded() ? (
                            <KeyboardArrowDown />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </IconButton>
                      </DataTableBodyCell>
                    )}
                    {row.getVisibleCells().map((cell, index) => (
                      <DataTableBodyCell key={index}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                  {collapseContent && row.getIsExpanded() && (
                    <TableRow>
                      <TableCell colSpan={columnsData.length + 1}>
                        {collapseContent(row.original)}
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        mt={1}
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          flexDirection: "row",
        }}
      >
        <Box>
          <span
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color: "dark",
              textTransform: "none",
              verticalAlign: "unset",
              opacity: 1,
            }}
          >
            {pagination && (
              <>
                Mostrando {pagination.currentPage * pageSize - pageSize + 1} a{" "}
                {pagination.currentPage * pageSize > pagination.count
                  ? pagination.count
                  : pagination.currentPage * pageSize}{" "}
                de {pagination.count} entradas de página
              </>
            )}
          </span>
        </Box>
        {pagination && (
          <Box display={"flex"}>
            <SuiPaginationTs
              item
              onClick={() =>
                pagination.onChangePage(pagination.currentPage - 1)
              }
              disabled={!pagination.previous}
            >
              <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
            </SuiPaginationTs>
            <SuiBoxTs width="5rem" mx={1}>
              <SuiInputTs
                inputProps={{
                  type: "number",
                  min: 1,
                  max: pagination.count / pageSize,
                  step: "1",
                }}
                value={pagination.currentPage}
                onChange={(e) => pagination.onChangePage(e.target.value)}
              />
            </SuiBoxTs>

            <SuiPaginationTs
              item
              disabled={!pagination.next}
              onClick={() =>
                pagination.onChangePage(pagination.currentPage + 1)
              }
            >
              <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
            </SuiPaginationTs>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SwappiDataTable;
